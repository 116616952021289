import * as types from "./types";

const initialState = {
  isSearchingMatches: false,
  searchMatchesDone: false,
  searchMatchesFailed: false,
  isSearchingClickoutsMatches: false,
  searchClickoutsMatchesDone: false,
  searchClickoutsMatchesFailed: false,
  showSearchingLoader: false,
  allMatches: {},
  submittedSchools: {},
  clickoutsMatches: [],
  submittedClickoutsSchools: {},
  selectedSchoolOptions: [],
  isSubmittingMatches: false,
  submitMatchesDone: false,
  submitMatchesFailed: false
};

const matchReducers = function (state = initialState, { type, payload }) {
  switch (type) {
    case types.SEARCH_MATCHES:
      return {
        ...state,
        searchData: { ...payload },
        isSearchingMatches: true,
        searchMatchesDone: false,
        searchMatchesFailed: false,
        showSearchingLoader: true
      };
    case types.SEARCH_MATCHES_DONE:
      return {
        ...state,
        isSearchingMatches: false,
        searchMatchesDone: true,
        searchMatchesFailed: false,
        allMatches: payload.data
      };
    case types.SEARCH_MATCHES_FAILED:
      return {
        ...state,
        isSearchingMatches: false,
        searchMatchesDone: false,
        searchMatchesFailed: true
      };
    case types.SUBMIT_MATCHES:
      return {
        ...state,
        isSubmittingMatches: true,
        submitMatchesDone: false,
        submitMatchesFailed: false,
        submittedSchools: {}
      };
    case types.SUBMIT_MATCHES_DONE:
      return {
        ...state,
        isSubmittingMatches: false,
        submitMatchesDone: true,
        submitMatchesFailed: false,
        submittedSchools: payload.data
      };
    case types.SUBMIT_MATCHES_FAILED:
      return {
        ...state,
        isSubmittingMatches: false,
        submitMatchesDone: false,
        submitMatchesFailed: true
      };
    case types.SEARCH_CLICKOUTS_MATCHES:
      return {
        ...state,
        isSearchingClickoutsMatches: true,
        searchClickoutsMatchesDone: false,
        searchClickoutsMatchesFailed: false,
        showSearchingLoader: true
      };
    case types.SEARCH_CLICKOUTS_MATCHES_DONE:
      return {
        ...state,
        isSearchingClickoutsMatches: false,
        searchClickoutsMatchesDone: true,
        searchClickoutsMatchesFailed: false,
        clickoutsMatches: payload.data
      };
    case types.SEARCH_CLICKOUTS_MATCHES_FAILED:
      return {
        ...state,
        isSearchingClickoutsMatches: false,
        searchClickoutsMatchesDone: false,
        searchClickoutsMatchesFailed: true
      };
    case types.SUBMIT_CLICKOUTS_MATCHES:
      return {
        ...state,
        isSubmittingClickoutsMatches: true,
        submitClickoutsMatchesDone: false,
        submitClickoutsMatchesFailed: false
      };
    case types.SUBMIT_CLICKOUTS_MATCHES_DONE:
      return {
        ...state,
        isSubmittingClickoutsMatches: false,
        submitClickoutsMatchesDone: true,
        submitClickoutsMatchesFailed: false,
        submittedSchools: payload.data
      };
    case types.SUBMIT_CLICKOUTS_MATCHES_FAILED:
      return {
        ...state,
        isSubmittingClickoutsMatches: false,
        submitClickoutsMatchesDone: false,
        submitClickoutsMatchesFailed: true
      };
    case types.RESET_MATCH_SEARCH:
      return {
        ...state,
        ...initialState
      };
    case types.SET_SELECTED_SCHOOL_OPTIONS:
      return {
        ...state,
        selectedSchoolOptions: payload
      };
    default:
      return state;
  }
};

export default matchReducers;
